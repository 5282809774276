export const registerServiceWorker = () => true;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then(function(registration) {
      // Registration was successful``
      console.log(
        'ServiceWorker registration successful with scope: ',
        registration.scope
      );
    })
    .catch(function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
}

// export const onClientEntry = () => {
//   console.log("We've started!");
// };

// export const onServiceWorkerUpdateFound = () => {
//   console.log('onServiceWorkerUpdateFound');
// };

// export const onServiceWorkerUpdateReady = () => {
//   console.log('onServiceWorkerUpdateReady');
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest version?`
//   );
//   if (answer === true) {
//     window.location.reload();
//   }
// };

// exports.onInitialClientRender = () => {
//   console.log('ReactDOM.render has executed');
// };

// exports.disableCorePrefetching = () => true;

// export const registerServiceWorker = () => true;

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest version?`
//   );

//   if (answer === true) {
//     window.location.reload();
//   }
// };

// // export const onServiceWorkerUpdateReady = () => {
// //   const answer = window.confirm(
// //     `This application has been updated. ` +
// //       `Reload to display the latest version?`
// //   );

// //   if (answer === true) {
// //     window.location.reload();
// //   }
// // };

// if ('serviceWorker' in navigator) {
//   (' ');
// }
// {
//   window.addEventListener('load', function() {
//     navigator.serviceWorker.register('/sw.js').then(
//       function(registration) {
//         console.log(
//           'ServiceWorker registration successful with scope: ',
//           registration.scope
//         );
//         Notification.requestPermission(function(result) {
//           if (result === 'granted') {
//             navigator.serviceWorker.ready.then(function(registration) {
//               showNotification();
//             });
//           }
//         });
//       },
//       function(err) {
//         console.log('ServiceWorker registration failed: ', err);
//       }
//     );
//   });
// }

// function showNotification() {
//   Notification.requestPermission(function(result) {
//     if (result === 'granted') {
//       navigator.serviceWorker.ready.then(function(registration) {
//         registration.showNotification('Vibration Sample', {
//           body: 'Buzz! Buzz!',
//           icon: 'logo/logo.png',
//           vibrate: [200, 100, 200, 100, 200, 100, 200],
//           tag: 'vibration-sample',
//         });
//       });
//     }
//   });
// }

// exports.registerServiceWorker = () => true;

// // var options = {
// //   body: 'Do you like my body?',
// //   data: 'I like peas.'
// // }

// // var n = new Notification('Test notification',options);

// // n.data
